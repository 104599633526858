import React from "react";
import { PageLayout } from "../components/page-layout";
import {Container, rem, Tabs} from "@mantine/core";
import {IconPhoto, IconMessageCircle, IconFaceIdError} from '@tabler/icons-react';
import {Sepa} from "../components/sila/sepa";
import {Upn} from "../components/sila/upn";
import {SepaDenied} from "../components/sila/sepa-denied";

export const AccountingPage: React.FC = () => {
  const iconStyle = { width: rem(18), height: rem(18) };

  return (<PageLayout>
    <Container size="lg" w="100%">
      <Tabs defaultValue="sepa">
        <Tabs.List>
          <Tabs.Tab value="sepa" leftSection={<IconMessageCircle style={iconStyle} />}>
            SEPA generator
          </Tabs.Tab>
          <Tabs.Tab value="sepa-denied" leftSection={<IconFaceIdError style={iconStyle} />}>
            SEPA zavrnitve
          </Tabs.Tab>
          <Tabs.Tab value="upn" leftSection={<IconPhoto style={iconStyle} />}>
            UPN generator
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="sepa">
          <Sepa />
        </Tabs.Panel>
        <Tabs.Panel value="sepa-denied">
          <SepaDenied />
        </Tabs.Panel>
        <Tabs.Panel value="upn">
          <Upn />
        </Tabs.Panel>
      </Tabs>
    </Container>
  </PageLayout>
)};
