import React from "react";
import {IconFaceIdError} from "@tabler/icons-react";
import {Blockquote, Code, Flex, Loader} from "@mantine/core";

interface Auth0FeatureProps {
  error?: Error | null;
  loading: boolean;
}

export const ErrorBox: React.FC<Auth0FeatureProps> = ({
  error,
    loading,
}) => (
    <>
      {loading && <Flex justify="center"><Loader /></Flex>}
      {error && (
          <Blockquote color="red" icon={<IconFaceIdError />} p="md">
            Nekaj je šlo narobe. Poskusite znova. Če še vedno ne deluje, kontaktirate podporo in jim sporočite napako.
            <Code mt="sm" block>{JSON.stringify(error ?? "")}</Code>
          </Blockquote>
      )}
    </>
);
