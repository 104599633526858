import React from "react";
import { PageLayout } from "../components/page-layout";
import {Container, Stack, Text, Title} from "@mantine/core";

export const HomePage: React.FC = () => {
  return (<PageLayout>
    <Container size="lg" w="100%">
      <Stack>
        <Title>Dobrodošel v Sila sistem</Title>
        <Text>Za kaj več se prijavi!</Text>
      </Stack>
    </Container>
  </PageLayout>
)};
