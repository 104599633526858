import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export default function useMantineMediaQueries(options?: {
  withInitialValues?: boolean;
}) {
  const theme = useMantineTheme();
  const withInitialValues = options?.withInitialValues === true;

  let initialValues: Record<string, boolean | undefined> = {
    isMobile: undefined,
    isSmall: undefined,
    isMedium: undefined,
    isLarge: undefined,
    isExtraLarge: undefined,
  };

  if (withInitialValues && typeof window !== "undefined" && window.matchMedia) {
    initialValues = {
      isMobile: !window.matchMedia(`(max-width: ${theme.breakpoints.sm})`)
        .matches,
      isSmall: window.matchMedia(`(min-width: ${theme.breakpoints.sm})`)
        .matches,
      isMedium: window.matchMedia(`(min-width: ${theme.breakpoints.md})`)
        .matches,
      isLarge: window.matchMedia(`(min-width: ${theme.breakpoints.lg})`)
        .matches,
      isExtraLarge: window.matchMedia(`(min-width: ${theme.breakpoints.xl})`)
        .matches,
    };
  }

  const isMobile = !useMediaQuery(
    `(min-width: ${theme.breakpoints.sm})`,
    initialValues.isMobile
  );
  const isSmall = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm})`,
    initialValues.isSmall
  );
  const isMedium = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`,
    initialValues.isMedium
  );
  const isLarge = useMediaQuery(
    `(min-width: ${theme.breakpoints.lg})`,
    initialValues.isLarge
  );
  const isExtraLarge = useMediaQuery(
    `(min-width: ${theme.breakpoints.xl})`,
    initialValues.isExtraLarge
  );

  return {
    isMobile,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
  };
}
