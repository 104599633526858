import React, { useState } from "react";
import {
    Blockquote,
    Button,
    Card,
    Checkbox,
    Flex,
    Group,
    Image,
    Modal,
    SimpleGrid,
    Stack,
    Text,
    Title
} from "@mantine/core";
import classes from './common.module.css';
import { useMutation, useQuery } from "@tanstack/react-query";
import { ErrorBox } from "../error-box";
import { Member } from "src/models/member";
import useMantineMediaQueries from "../../hooks/useMantineMediaQueries";
import { useAuth0 } from "@auth0/auth0-react";
import { IconCheck } from "@tabler/icons-react";

const getUpnPreview = async (token: string) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/upn/generate`, {
            headers: {
                Authorization: `Bearer ${token}`, // Attach the Auth0 token to the request
            },
        }
    )
    if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
    return await response.json();
}

const sendUpn = async (token: string, ignoredUsers: number[] = []) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/upn/send`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ignoredUsers }),
        });
    return await response.json();
}

export const Upn: React.FC = () => {
    const [ignoredUsers, setIgnoredUsers] = useState<number[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const { data, refetch, isLoading, error } = useQuery<{ member: Member, image: string }[]>({
        queryKey: ['upn-preview'],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return getUpnPreview(token);
        },
    });

    const { mutate, data: sendData, error: sendError } = useMutation({
        mutationFn: async (ignoredUsers: number[]) => {
            const token = await getAccessTokenSilently();
            return sendUpn(token, ignoredUsers);
        }
    });

    const { isLarge } = useMantineMediaQueries({ withInitialValues: true });

    const toggleUser = (userId: number, ignore: boolean) => {
        setIgnoredUsers((prev) =>
            ignore ? [...prev, userId] : prev.filter((id) => id !== userId)
        );
    }

    const handleSend = () => {
        setModalOpen(true);
    }

    const confirmSend = () => {
        setModalOpen(false);
        mutate(ignoredUsers); // Trigger the mutation to send the UPNs
    }

    const total = data?.reduce((acc, item) => acc + parseFloat(String(item?.member.amount ?? 0)), 0);
    const formattedTotal = new Intl.NumberFormat('si-SL', { style: 'currency', currency: 'EUR' }).format(total ?? 0);

    return (
        <Card shadow="sm" padding="lg" radius="md" className={classes.card}>
            <Stack>
                <Flex justify="space-between" align="center">
                    <Title>UPN</Title>
                    <Group gap="xs">
                        <Button variant="outline" onClick={() => refetch()}>Osveži</Button>
                        <Button onClick={handleSend}>Pošlji</Button>
                    </Group>
                </Flex>
                {sendData && (
                    <Blockquote icon={<IconCheck />} p="md">
                        Uspešno poslano!
                    </Blockquote>
                )}
                <Stack>
                    <SimpleGrid cols={isLarge ? 2 : 1}>
                        {data?.map(({ member, image }) => (
                            <Card key={member.id} shadow="sm" padding="sm" radius="sm" withBorder>
                                <Stack gap="xs">
                                    <Flex justify="space-between" align="center">
                                        <Group gap="xs">
                                            <Title order={3}>{member.name}</Title>
                                            <Text size="xs">({member.email})</Text>
                                        </Group>
                                        <Checkbox
                                            label="Ne pošlji"
                                            onChange={(event) => toggleUser(member.id, event.currentTarget.checked)}
                                        />
                                    </Flex>
                                    <Image src={image} alt={member.name} />
                                </Stack>
                            </Card>
                        ))}
                    </SimpleGrid>
                </Stack>
                <ErrorBox error={error} loading={isLoading} />
                <ErrorBox error={sendError} loading={false} />
                <Text fw={500}>Skupna vsota: {formattedTotal}</Text>
            </Stack>

            {/* Confirmation Modal */}
            <Modal
                opened={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Potrditev pošiljanja"
            >
                <Text>Ali ste prepričani, da želite poslati UPN naloge? To dejanje je nepreklicno.</Text>
                <Group mt="md">
                    <Button variant="default" onClick={() => setModalOpen(false)}>
                        Prekliči
                    </Button>
                    <Button color="red" onClick={confirmSend}>
                        Potrdi in pošlji
                    </Button>
                </Group>
            </Modal>
        </Card>
    );
};
